import cx from "classnames"
import PropTypes from "prop-types"

/* Settings */

const Settings = ({ title, children, className, showDefaultsHint }) => {
  return (
    <div className={className}>
      <h3 className="type-heading-2xl font-bold">{title}</h3>
      {showDefaultsHint && (
        <p className="mt-2 text-sm italic text-spun-pearl">
          {showDefaultsHint}
        </p>
      )}
      <div className="mt-9 space-y-9">{children}</div>
    </div>
  )
}

Settings.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  showDefaultsHint: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

/* SettingsField */

export const SettingsField = ({
  className,
  htmlFor,
  label,
  instructions,
  children,
  defaultValue,
}) => {
  return (
    <div className={cx("flex", className)}>
      <div className="w-64 pt-4">
        <label className={cx("type-body-base", { "cursor-pointer": !!htmlFor })} htmlFor={htmlFor}>
          {label}
          {defaultValue && (
            <div className="mt-1 text-sm italic text-spun-pearl">{defaultValue}</div>
          )}
        </label>
      </div>
      <div className="flex-1 pt-px">
        {children}
        {instructions && (
          <ul className="type-body-base mt-3 list-none space-y-1 text-spun-pearl">
            {instructions.map((instruction, index) => {
              return <li key={index}>{instruction}</li>
            })}
          </ul>
        )}
      </div>
    </div>
  )
}

SettingsField.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string,
  instructions: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

/* SettingsFooter */

export const SettingsFooter = ({ children }) => {
  return <div className="mt-11 flex gap-x-4 pl-64">{children}</div>
}

export default Settings
