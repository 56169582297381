import cx from "classnames"
import { ReactNode } from "react"
import { NavLink } from "react-router-dom"

type SidebarProps = {
  children: ReactNode
}

type SidebarMenuProps = {
  children: ReactNode
}

type SidebarMenuLinkProps = {
  link: string
  icon: ReactNode
  label: string | ReactNode
}

type SidebarMenuButtonProps = {
  onClick: () => void
  icon: ReactNode
  label: string | ReactNode
  className?: string
}

const Sidebar = ({ children }: SidebarProps) => {
  return (
    <aside className="flex h-full w-80 flex-col gap-y-5 border-r border-mine-shaft px-5 pt-8">
      {children}
    </aside>
  )
}

export const SidebarMenu = ({ children }: SidebarMenuProps) => {
  return <ul className="w-full space-y-2">{children}</ul>
}

export const SidebarMenuLink = ({ link, icon, label }: SidebarMenuLinkProps) => {
  return (
    <li>
      <NavLink
        to={link}
        className={({ isActive }) =>
          cx(
            "type-body-base flex w-full cursor-pointer items-center rounded-md border border-tundora px-3 py-1.5 font-bold transition-colors",
            isActive ? "bg-mine-shaft" : "hover:border-silver"
          )
        }
        end
      >
        <div className="mr-4 w-4">{icon}</div>
        {label}
      </NavLink>
    </li>
  )
}

export const SidebarMenuButton = ({ onClick, icon, label, className }: SidebarMenuButtonProps) => {
  return (
    <li>
      <button
        onClick={onClick}
        className={cx(
          "type-body-base flex w-full cursor-pointer items-center rounded-md border border-tundora px-3 py-1.5 font-bold transition-colors hover:border-silver",
          className
        )}
      >
        <div className="mr-4 w-4">{icon}</div>
        {label}
      </button>
    </li>
  )
}

export default Sidebar
