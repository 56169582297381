import { Modal as NextUIModal } from "@nextui-org/modal"

import { ReactComponent as CloseIcon } from "@/icons/close-icon.svg"

type ModalProps = {
  children: React.ReactNode
  title?: string
  isOpen: boolean
  onClose: () => void
}

const Modal = ({ children, title, ...props }: ModalProps) => {
  return (
    <NextUIModal
      classNames={{
        body: "pt-12 pb-6 px-12",
        backdrop: "bg-black bg-opacity-70",
        base: "max-w-6xl border border-mine-shaft bg-dark text-spun-pearl",
        header: "border-b-0",
        footer: "border-t-0 px-12",
        closeButton: "text-white w-6 h-6 p-0 top-7 right-7",
      }}
      closeButton={<CloseIcon />}
      title={title}
      {...props}
    >
      {children}
    </NextUIModal>
  )
}

export default Modal
