import Label from "@/components/forms/Label"
import NumberInput from "@/components/forms/NumberInput"
import RadioGroup from "@/components/forms/RadioGroup"
import { useState, useEffect } from "react"

/**
 * PreampGainSettings Component
 * 
 * Manages preamp gain settings with two modes:
 * - AUTO: Firmware automatically calculates gain to prevent digital clipping
 * - MANUAL: User-controlled gain settings
 * 
 * Auto Save Behavior:
 * Changes to preamp settings follow the global auto-save configuration:
 * - "immediate": Changes are instantly applied and saved to device flash
 * - "disabled": Changes are only auditioned until manually saved
 * - "10s"/"1min": Changes are auditioned immediately and saved after delay
 * 
 * Note: When in AUTO mode, gain values are calculated automatically and
 * are not affected by auto-save settings. Only changes to the mode itself
 * (switching between AUTO/MANUAL) follow auto-save behavior.
 */
const PreampGainSettings = ({ eqSettings, onEqChange, mode }) => {
  const [preampMode, setPreampMode] = useState()
  const [preampGain, setPreampGain] = useState()

  /**
   * On settings change
   *
   * When parent settings change, update preamp settings
   */
  useEffect(() => {
    if (eqSettings) {
      setPreampMode(eqSettings["Preamp"]["Mode"]["Current"])
      setPreampGain(eqSettings["Preamp"]["Gain"]["Current"])
    }
  }, [eqSettings, mode])

  /**
   * Update
   *
   * @param {*} domain
   * @param {*} value
   * @returns
   */
  const update = async (domain, value) => {
    const success = await onEqChange([["Preamp", domain, value]])
    return success
  }

  return (
    <div className="flex items-center gap-x-5">
      <Label
        text="Preamp Gain"
        info={
          <div className="max-w-md space-y-1">
            <p>
              Equalization is a 3-step process: Apply negative preamp gain, apply DSP filter(s), and
              compensate volume.
            </p>
            <p>
              In <strong>AUTO</strong> mode, preamp gain is automatically calculated to avoid
              digital clipping. We use a fast algorithm to avoid nearly all chances of clipped
              audio, but do not calculate overlap of adjacent filters.
            </p>
            <p>
              Use <strong>MANUAL</strong> mode if you know what you are doing! You may hear distortion
              like wind in one or both channels when preamp gain is inadequate for your filter settings.
            </p>
            <p>We recommend at least 2 dB of headroom for all DSP filters to avoid overflow.
              That is: Preamp Gain = -(2 + max filter gain)
            </p>
          </div>
        }
      />
      <div className="flex items-center gap-x-3">
        <RadioGroup
          selected={preampMode}
          items={eqSettings["Preamp"]["Mode"]["Elements"].map((settingsValue) => {
            return {
              label: settingsValue,
              value: settingsValue,
            }
          })}
          onValueChange={(value) => update("Mode", value)}
        />
        <NumberInput
          value={preampGain}
          min={eqSettings["Preamp"]["Gain"]["Min"]}
          max={eqSettings["Preamp"]["Gain"]["Max"]}
          onDebouncedChange={(value) => update("Gain", value)}
          disabled={preampMode === "AUTO"}
        />
      </div>
    </div>
  )
}

export default PreampGainSettings
