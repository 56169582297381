import Button from "@/components/Button"
import { ReactComponent as BinIcon } from "@/icons/bin-icon.svg"
import { ReactComponent as LinkIcon } from "@/icons/link-icon.svg"
import { ReactComponent as StarIcon } from "@/icons/star-icon.svg"
import type { Preset } from "@/hooks/presets"
import cx from "classnames"
import { useSerialDevice } from "@/contexts/serial-device"
import { DEVICE_CONNECTED } from "@/constants"
import { useAuth } from "@/contexts/auth"
import { ReactComponent as InfoIcon } from "@/icons/info-icon.svg"
import Tooltip from "@/components/Tooltip"
import { Select as NextUISelect, SelectItem } from "@nextui-org/react"

export type AutoSaveMode = "disabled" | "immediate" | "10s" | "1min"

const PresetHeader = ({
  activePreset,
  activePresetOutdated,
  onDeletePresetClicked,
  onExportClicked,
  onFavouritePresetClicked,
  onSavePresetClicked,
  onSaveNewPresetClicked,
  onGetLinkClicked,
  autoSave = "immediate",
  onAutoSaveChange,
}: {
  activePreset: Preset
  activePresetOutdated: boolean
  onDeletePresetClicked: (preset: Preset) => void
  onExportClicked: () => void
  onFavouritePresetClicked: (preset: Preset) => void
  onSavePresetClicked: (preset: Preset) => void
  onSaveNewPresetClicked: (preset: Preset) => void
  onGetLinkClicked: () => void
  autoSave?: AutoSaveMode
  onAutoSaveChange: (value: AutoSaveMode) => void
}) => {
  const { user } = useAuth()
  const serialDevice = useSerialDevice()
  const isDeviceConnected = serialDevice.status === DEVICE_CONNECTED

  const autoSaveOptions = [
    { value: "immediate", label: "Save immediately" },
    { value: "disabled", label: "Disabled" },
    { value: "10s", label: "Save after 10 seconds" },
    { value: "1min", label: "Save after 1 minute" },
  ]

  return (
    <div className="flex items-center justify-between">
      {activePreset ? (
        <div className="type-heading-base inline-flex items-center font-bold">
          {activePreset.name}
          <button
            title={"Make this preset a favourite"}
            className="cursor-pointer"
            onClick={() => onFavouritePresetClicked(activePreset)}
          >
            <StarIcon
              className={cx(
                "-mt-px ml-4 h-4 w-4",
                activePreset.is_favourite ? "text-yellow" : "text-gray-300"
              )}
            />
          </button>
        </div>
      ) : (
        <div className="type-heading-base inline-flex items-center font-bold">No Preset Loaded</div>
      )}
      <div className="flex items-center gap-x-2">
        {activePreset && (
          <>
            {activePresetOutdated && (
              <>
                <span className="mr-1 text-xs italic text-dark-blue">Preset changed</span>
                <Button
                  theme="secondary"
                  size="small"
                  title={`Save EQ changes to current '${activePreset.name}' preset`}
                  disabled={!activePresetOutdated}
                  outline
                  onClick={() => onSavePresetClicked(activePreset)}
                  className=""
                  full={false}
                >
                  Update Preset
                </Button>
              </>
            )}
          </>
        )}
        {user && (
          <Button
            theme="secondary"
            size="small"
            title="Create a new preset from current EQ settings"
            outline
            onClick={() => onSaveNewPresetClicked(activePreset)}
            disabled={!isDeviceConnected}
            className=""
            full={false}
          >
            Save As New Preset
          </Button>
        )}
        <Button
          className="pr-2"
          theme="secondary"
          size="small"
          outline
          onClick={() => onGetLinkClicked()}
          full={false}
        >
          Get link
          <LinkIcon className="-mt-0.5 h-3 w-3 text-brightest-blue" />
        </Button>
        <Button
          theme="secondary"
          size="small"
          outline
          onClick={() => onExportClicked()}
          title="Export the current EQ settings"
          disabled={!isDeviceConnected}
          className=""
          full={false}
        >
          Export
        </Button>
        {activePreset && (
          <div className="flex items-center pl-1">
            <button
              className="clickable h-4 w-4 text-silver"
              title="Delete this preset"
              onClick={() => onDeletePresetClicked(activePreset)}
            >
              <BinIcon />
            </button>
          </div>
        )}
        <div className="ml-2 flex items-center gap-2 border-l border-mine-shaft pl-2">
          <div className="flex items-center">
            <span className="text-sm text-gray-600">Auto Save</span>
            <Tooltip
              content={
                "Auto Save: Element IV briefly mutes audio to save changes to flash memory. You may Disable Auto Save or delay Save events to hear EQ changes in real-time. Just remember to manually save your settings when choosing Disabled to prevent loss upon powering down."
              }
              placement="bottom"
              classNames={{
                content:
                  "max-w-[400px] px-5 py-2 bg-red rounded-md type-body-sm bg-tundora text-silver",
              }}
            >
              <span className="ml-1 cursor-help">
                <InfoIcon className="h-3 w-3 text-dove-gray" />
              </span>
            </Tooltip>
          </div>
          <NextUISelect
            radius="sm"
            size="sm"
            disallowEmptySelection
            selectionMode="single"
            selectedKeys={new Set([autoSave])}
            onSelectionChange={(keys) => {
              const newValue = Array.from(keys)[0] as AutoSaveMode
              console.log("Auto save changed to:", newValue)
              onAutoSaveChange(newValue)
            }}
            classNames={{
              trigger:
                "bg-dark border data-[hover=true]:bg-dark border-mine-shaft data-[hover=true]:border-silver transition-colors",
              value: "text-white group-data-[has-value=true]:text-white type-body-base font-bold",
              popoverContent: "bg-dark rounded-lg border border-mine-shaft p-px",
            }}
            className="w-48"
            aria-label="Auto Save Mode"
          >
            {autoSaveOptions.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </NextUISelect>
        </div>
      </div>
    </div>
  )
}

export default PresetHeader
