import WarningCallout from "@/components/WarningCallout"
import { ReactComponent as BrowserIcon } from "@/icons/browser-icon.svg"

const MobileWarningOverlay = () => {
  return (
    <section className="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-jaguar p-5 lg:hidden">
      <div className="mx-auto max-w-md space-y-4 px-7 text-silver">
        <BrowserIcon className="mb-7 h-20 w-20" />
        <h1 className="type-body-xl font-bold">
          Sorry, smartphones are currently not supported by JDS Labs Core.
        </h1>
        <p>Please switch to a supported desktop browser:</p>
        <ul className="list-disc pl-5">
          <li>Google Chrome</li>
          <li>Microsoft Edge</li>
          <li>Opera</li>
          <li>Brave</li>
          <li>Firefox*</li>
        </ul>
        <p>
          *Firefox requires the{" "}
          <a
            href="https://addons.mozilla.org/en-US/firefox/addon/webserial-for-firefox/"
            className="text-red"
            target="_blank"
          >
            WebSerial for Firefox
          </a>{" "}
          extension.
        </p>
        <p className="mt-8">
          Note: Window must be at least 1024px wide and in landscape orientation for optimal rendering of the equalizer canvas.
        </p>
      </div>
    </section>
  )
}

export default MobileWarningOverlay
