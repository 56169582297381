import Button from "@/components/Button"
import RadioGroup from "@/components/forms/RadioGroup"
import Select from "@/components/forms/Select"
import Slider from "@/components/forms/Slider"
import TextInput from "@/components/forms/TextInput"
import InlineNotification from "@/components/InlineNotification"
import SettingsSidebar from "@/components/interface/SettingsSidebar"
import Layout from "@/components/layouts/DefaultLayout"
import LoadingSpinner from "@/components/LoadingSpinner"
import Settings, { SettingsField, SettingsFooter } from "@/components/Settings"
import { DEVICE_CONNECTED, DEVICE_DISCONNECTED, DEVICE_SETTINGS_URL, NO_DEVICES } from "@/constants"
import { useSerialDevice } from "@/contexts/serial-device"
import { DEFAULT_SETTINGS } from "@/settings"
import { buildSettingsUpdate, toSettingsUpdate } from "@/utils/device"
import { useEffect, useState } from "react"
import { Outlet as SubPage, useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import cx from "classnames"

const DEVICE_SETTINGS_TOAST_ID = "device-settings-toast"

const DeviceSettingsPage = () => {
  const device = useSerialDevice()
  const navigate = useNavigate()
  const location = useLocation()

  if (device.status === NO_DEVICES) {
    navigate("/")
    toast.warning("Device must be connected")
  }

  return (
    <Layout>
      <SettingsSidebar />
      <div className="relative w-full flex-1 overflow-y-auto px-20 py-14">
        {location.pathname === DEVICE_SETTINGS_URL ? <MainPage /> : <SubPage />}
      </div>
    </Layout>
  )
}

const SETTINGS_CONFIG = [
  {
    label: "General Settings",
    key: "UI",
    fields: [
      {
        fieldClassName: "pt-3",
        key: "Screen Timeout",
        instructions: [
          "Warning: Choosing 'Never' may result in burn-in.",
        ],
        component: Select,
      },
      {
        key: "Screen Contrast",
        component: Select,
      },
      {
        key: "LED Mode",
        component: Select,
        instructions: [
          "ON: LEDs remain on while Element IV is on",
          "WITH SCREEN: LEDs turn off while the screen is off.",
          "5 MIN STANDBY: LEDs turn off after 5 minutes of USB inactivity.",
          ({ Elements }) => (Elements.includes("OFF") ? "OFF: LEDs are always off" : null),
        ],
      },
      {
        key: "Knob Speed",
        label: "Volume Knob Speed",
        component: RadioGroup,
        componentClassName: "mt-3",
        instructions: [
          "Choose HALF to make the volume knob feel more gradual, or choose FULL for quickest volume adjustment.",
        ],
      },
      {
        key: "Volume Steps",
        label: "Volume Knob Steps",
        component: RadioGroup,
        componentClassName: "mt-3",
        instructions: [
          'Defines how much the listening volume increases or decreases per "step" as the knob is turned.',
        ],
      },
      {
        key: "Auto Gain Speed",
        component: Select,
        instructions: [
          "Automatic gain occurs at the 0dB threshold: Aggressive transitions between low/high gain rapidly, whereas the Default setting displays a progress bar and requires more knob turning to enact the gain transition.",
        ],
      },
      {
        key: "Knob Button",
        label: "Knob Button Behavior",
        component: Select,
      },
    ],
  },
  {
    label: "Digital Audio Settings",
    key: "General",
    fields: [
      {
        key: "UAC Mode",
        label: "USB Audio Class",
        instructions: [
          "UAC2 is recommended for macOS, Linux, and Windows 10/11.",
          "Chose UAC1 for PS4/PS5/Nintendo Switch. NOTE: This app is not supported in UAC1 mode. To reconnect to Core, long press the knob button and choose USB Audio Class --> UAC2.",
        ],
        component: Select,
      },
      {
        key: "SPDIF mode",
        label: "Optical Processing Mode",
        instructions: [
          "ESS S/PDIF: Optical input will be decoded by the ESS DAC chip (best quality)",
          "XMOS S/PDIF (DSP): Optical input will be decoded by Element IV's processor with DSP support",
        ],
        component: Select,
      },
      {
        key: "Inactive Mute",
        label: "Mute Inactive USB",
        instructions: ['Enable to eliminate "alien sounds" in Linux/Android/ChromeOS.'],
        component: RadioGroup,
      },
    ],
  },
  {
    label: "DAC Configuration",
    key: "DAC",
    fields: [
      {
        key: "Max Sample Rate",
        fieldClassName: "pt-8",
        instructions: [
          "All 10-band EQ functionality is available up to 32/192kHz. Please note that Peaking filters are ignored at 352.4-384kHz; bass and treble controls remain available (LP and HP filters). DAC will reboot upon changing this setting.",
        ],
        component: Select,
      },
      {
        key: "Second Harmonic Compensation",
        label: "2nd Harmonic Compensation",
        fieldClassName: "pt-8",
        componentClassName: "mt-3 max-w-4xl",
        component: "SliderWithInput",
        step: 1,
        instructions: [
          "Element 4 is tuned for optimal performance: altering this value in either direction will increase the 2nd harmonic for a slightly harsher sound.",
        ],
      },
      {
        key: "Third Harmonic Compensation",
        label: "3rd Harmonic Compensation",
        fieldClassName: "pt-8",
        componentClassName: "mt-3 max-w-4xl",
        component: "SliderWithInput",
        step: 1,
        instructions: [
          "Element 4 is tuned for optimal performance: altering this value in either direction will increase the 3rd harmonic for a slightly warmer sound.",
        ],
      },
      {
        key: "DPLL Bandwidth",
        fieldClassName: "pt-8",
        componentClassName: "mt-3 max-w-4xl",
        component: "SliderWithInput",
        step: 1,
        instructions: [
          "This setting impacts jitter rejection of the I2S stream. The default value is suitable for most PCs and modern TVs. Try increasing DPLL Bandwidth if you encounter stuttering via TOSLINK.",
        ],
      },
      {
        key: "SPDIF Deemphasis",
        component: RadioGroup,
      },
      {
        key: "DAC Filter",
        component: Select,
        instructions: [
          "Interpolation filters of the ESS DAC. Most listeners find it difficult to perceive any noticeable difference between interpolation filters; we recommend using the default setting (Fast Rolloff).",
          "FAST ROLLOFF produces a steep high-frequency cut near the Nyquist frequency to minimize aliasing. Audible Impact: This yields a precise, detailed sound with sharper high-frequency extension.",
          "SLOW ROLLOFF provides a gradual attenuation near the Nyquist limit, preserving a smoother high-frequency response. Audible Impact: This results in a warm, natural sound with less high-frequency detail.",
          "MIN PHASE avoids pre-ringing by focusing all impulse response energy post-initial sound, maintaining phase accuracy. Audible Impact: This provides a natural, lifelike sound with coherent timing, making vocals and acoustic instruments feel more present.",
        ],
      },
    ],
  },
]

/**
 * Render a field from a configuration. This allows us to more easily render
 * all of the settings fields by simply providing a configuration object that
 * looks like:
 *
 * const FIELDS = [{
 *   key: "Field A",
 *  component: Select
 * }, {
 *   key: "Field A",
 *  component: Select
 * }]
 *
 * and using:
 *
 * {
 *   FIELDS.map((field) =>
 *     renderSettingsField({ settings, section: "General", field, onChange })
 *   )
 * }
 *
 * For slider fields (component: "SliderWithInput"), a specialized SliderField component
 * is used that provides:
 * - Immediate visual feedback during slider movement
 * - Debounced updates to the device to prevent excessive communication
 * - Automatic reversion to previous value if device update fails
 * - Display of current value alongside the slider
 *
 * @param settings - The current device settings object
 * @param section - The settings section (e.g., "UI", "General", "DAC")
 * @param field - The field configuration object from SETTINGS_CONFIG
 * @param onChange - Callback function to update device settings
 * @returns A rendered settings field component
 */
const SliderField = ({ currentValue, onChange, settings, section, field }) => {
  const [displayValue, setDisplayValue] = useState(currentValue)

  // Update display value when currentValue changes from device
  useEffect(() => {
    setDisplayValue(currentValue)
  }, [currentValue])

  const handleChange = (value) => {
    setDisplayValue(value)
  }

  const handleRelease = async (value) => {
    try {
      await onChange(["Configuration", section, field.key], value)
    } catch (error) {
      // If the device update fails, revert to the current value
      setDisplayValue(currentValue)
    }
  }

  return (
    <div className="flex items-center gap-x-4">
      <Slider
        value={displayValue}
        onChange={handleChange}
        onDebouncedChange={handleRelease}
        onPointerUp={() => handleRelease(displayValue)}
        minValue={settings["Configuration"][section][field.key]["Min"]}
        maxValue={settings["Configuration"][section][field.key]["Max"]}
        step={field.step || 0.1}
        formatValue={(value) => value.toString()}
        className={cx("flex-1", field.componentClassName || "")}
      />
      <div className="w-24 text-center">Value: {displayValue}</div>
    </div>
  )
}

const renderSettingsField = ({ settings, section, field, onChange }) => {
  if (!settings["Configuration"][section][field.key]) {
    return null
  }

  const defaultValue = DEFAULT_SETTINGS["Configuration"][section][field.key]["Current"]
  const currentValue = settings["Configuration"][section][field.key]["Current"]

  const componentProps = {
    className: field.componentClassName || "",
    "aria-label": field.label || field.key,
  }
  if (field.component === Select || field.component === RadioGroup) {
    componentProps["selected"] = currentValue
    componentProps["items"] = settings["Configuration"][section][field.key]["Elements"].map(
      (settingValue) => {
        return {
          label: settingValue,
          value: settingValue,
        }
      }
    )
    if (field.component === RadioGroup) {
      componentProps["onValueChange"] = (value) => {
        onChange(["Configuration", section, field.key], value)
      }
    }
    if (field.component === Select) {
      componentProps["onSelectionChange"] = (values) => {
        values.forEach((value) => {
          onChange(["Configuration", section, field.key], value)
        })
      }
    }
  }

  return (
    <SettingsField
      key={field.key}
      label={field.label || field.key}
      className={field.fieldClassName}
      instructions={field.instructions
        ?.map((instruction) =>
          typeof instruction === "function"
            ? instruction(settings["Configuration"][section][field.key])
            : instruction
        )
        .filter(Boolean)}
      defaultValue={defaultValue ? `(Default: ${defaultValue})` : undefined}
    >
      {field.component === "SliderWithInput" ? (
        <SliderField
          currentValue={currentValue}
          onChange={onChange}
          settings={settings}
          section={section}
          field={field}
        />
      ) : (
        <field.component {...componentProps} />
      )}
    </SettingsField>
  )
}

const MainPage = () => {
  const device = useSerialDevice()
  const [settings, setSettings] = useState()

  /**
   * When a setting changes, push to device
   *
   * @param keys
   * @param value
   */
  const onChange = async (keys, value) => {
    const settingUpdates = buildSettingsUpdate({}, keys, value)
    try {
      await device.updateSettings(settingUpdates)
      // Update local state to reflect the change
      setSettings((prevSettings) => {
        const newSettings = structuredClone(prevSettings)
        // Navigate to the correct nested location using the keys array
        let current = newSettings
        for (let i = 0; i < keys.length - 1; i++) {
          current = current[keys[i]]
        }
        current[keys[keys.length - 1]]["Current"] = value
        return newSettings
      })
    } catch (error) {
      toast.error("Error updating setting")
    }
  }

  /**
   * When the factory reset button is clicked, convert the default settings
   * into a format that can be sent to the device.
   *
   */
  const onFactoryResetClicked = async () => {
    const confirmation = await window.confirm("Are you sure you want to reset factory settings")
    if (confirmation) {
      const factoryResetSettings = toSettingsUpdate(DEFAULT_SETTINGS)
      try {
        await device.updateSettings(factoryResetSettings)
        toast.success("Device reset to factory settings", {
          toastId: DEVICE_SETTINGS_TOAST_ID,
          updateId: DEVICE_SETTINGS_TOAST_ID,
        })
      } catch (error) {
        toast.error("Error resetting device")
      }
    }
  }

  /**
   * Load settings from the device
   *
   */
  useEffect(() => {
    if (device.status === DEVICE_CONNECTED) {
      ;(async () => {
        const response = await device.getSettings()
        setSettings(response)
      })()
    }
  }, [device.status])

  if (device.status === DEVICE_DISCONNECTED) {
    return (
      <InlineNotification level="error" showIcon={false}>
        <>
          Your device has been disconnected. Please reconnect the device in order to update the
          settings.
        </>
      </InlineNotification>
    )
  }

  if (!settings) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <span className="flex items-center gap-2">
          <LoadingSpinner size="small" />{" "}
          <span className="text-xs text-gray-500">LOADING DEVICE SETTINGS</span>
        </span>
      </div>
    )
  }

  return (
    <>
      {SETTINGS_CONFIG.map((section, index) => {
        return (
          <Settings
            key={section.key}
            title={section.label}
            showDefaultsHint={index === 0 ? "Please note that changes take effect after turning the knob." : undefined}
            className="mb-12"
          >
            {section.fields.map((field) =>
              renderSettingsField({
                settings,
                section: section.key,
                field,
                onChange,
              })
            )}
          </Settings>
        )
      })}
    </>
  )
}

export default DeviceSettingsPage
