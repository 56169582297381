import cx from "classnames"

import { ReactComponent as PowerIcon } from "@/icons/power-icon.svg"

const PowerToggle = ({ 
  isActive = false, 
  canEnable = false,
  disable 
}: { 
  isActive: boolean;
  canEnable?: boolean;
  disable: () => void 
}) => {
  return (
    <button
      className={cx(
        "ml-2 flex h-5 w-5 items-center justify-center rounded-full border",
        (isActive || canEnable) && "clickable",
        isActive ? "border-green bg-dark-green" : "border-silver bg-tundora"
      )}
      disabled={!isActive && !canEnable}
      onClick={() => disable()}
    >
      <PowerIcon className={cx("h-3 w-3", isActive ? "text-green" : "text-silver")} />
    </button>
  )
}

export default PowerToggle
